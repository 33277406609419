import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Navbar from "../../Navbar";
import "../../css/Order.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import {
  getCustomer,
  getSession,
  getSessionAttentions,
} from "../../config/api";
import { parseDni, parseStatus, parseTime } from "../../config/utils";
import ClientTable from "../../Components/ClientTable";
import moment from "moment";
import { CustomerData } from "../../Components/CustomerData";
import { Button } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";

function SessionDetail() {
  const { id } = useParams();

  const [session, setSession] = useState();
  const [customer, setCustomer] = useState();
  const [attentions, setAttentions] = useState([]);

  const statusColor = (status) => {
    if (status === "WAITING") return "#fd4343";
    if (status === "IN_PROCESS") return "#fcc33c";
    return "#05934e";
  };

  const { getAccessTokenSilently } = useAuth0();

  const getToken = async () => await getAccessTokenSilently();

  useEffect(() => {
    getToken().then((accessToken) => {
      getSession(id, accessToken).then((session) => {
        setSession(session);
        console.log(session);
        getCustomer(session.customerId, accessToken).then((data) => {
          setCustomer(data);
        });
        getSessionAttentions(session.id, accessToken).then((data) => {
          const tableData = data.items.map((attention) => ({
            ...attention,
            waitingTime: parseTime(attention.startTime, attention.assignTime),
            duration: parseTime(attention.assignTime, attention.finishTime),
            queueName: attention.queue.name,
            agentName: attention.agent?.name,
            status: parseStatus(attention.status),
            formattedDate: moment(attention.startTime).format("DD/MM/YYYY"),
            statusColor: statusColor(attention.status),
          }));
          setAttentions(tableData);
        });
      });
    });
  }, []);

  const columns = [
    {
      name: "Fecha",
      type: "col-1",
      href: "/attention/",
      content: "formattedDate",
    },
    {
      name: "Cola",
      type: "col-1",
      href: "/attention/",
      content: "queueName",
    },
    {
      name: "Tiempo en Cola",
      type: "col-1",
      href: "/attention/",
      content: "waitingTime",
    },
    {
      name: "Agente",
      type: "col-2",
      href: "/attention/",
      content: "agentName",
    },
    {
      name: "Duración",
      type: "col-1",
      href: "/attention/",
      content: "duration",
    },
    {
      name: "Estado",
      type: "col-1",
      href: "/attention/",
      content: "status",
    },
    {
      name: "Tipificacion",
      type: "col-2",
      href: "/attention/",
      content: "endReason",
    },
  ];
  return (
    <>
      <Navbar />
      {session && (
        <main>
          <div className="page-header">
            <h1 className="page-title">Sesión</h1>
            <FontAwesomeIcon
              icon={faUser}
              style={{ paddingRight: "3rem", paddingBottom: "0.7rem" }}
              size="3x"
            />
          </div>
          <hr />
          <h2 className="client-title">Datos de sesión</h2>
          <div className="client-data" style={{ borderLeftColor: "#fcc33c" }}>
            <div>
              <label id="nombre">Canal de contacto</label>
              <input disabled={true} value={"Webchat"}></input>
            </div>
            <div>
              <label id="apellido">Atención</label>
              <input
                disabled={true}
                value={attentions.length ? "Agente" : "Bot"}
              />
            </div>
            <div>
              <label id="sucursal">Inicio de conversacion</label>
              <input disabled={true} value={new Date(session.loginTime)} />
            </div>
            <div>
              <label id="telefono">Conversación</label>
              <Button
                style={{ marginTop: 10 }}
                onClick={() =>
                  window.open(
                    "https://go.botmaker.com/#/chats/" + session.botmakerId
                  )
                }
              >
                Ir a la conversación
              </Button>
            </div>
          </div>
          <h2 className="client-title">
            <a href={customer && "/customer/" + session.customerId}>
              Datos del cliente
            </a>
          </h2>
          {/*edit ? (
              <a className="client-edit" onClick={() => setEdit(!edit)}>
                Editar <FontAwesomeIcon icon={faPencilAlt} />
              </a>
            ) : (
              <a className="client-edit" onClick={() => setEdit(!edit)}>
                Guardar <FontAwesomeIcon icon={faSave} />
              </a>
            )*/}

          <CustomerData customer={customer} edit={true} />

          <h2 className="client-title os">Atenciones</h2>
          <ClientTable
            list={attentions}
            columns={columns}
            link={"/attention/"}
          />
        </main>
      )}
    </>
  );
}

export default SessionDetail;
