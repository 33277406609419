import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

export default function AuthProvider({ children }) {
  const navigate = useNavigate();

  const domain =
    process.env.REACT_APP_AUTH0_DOMAIN ?? "https://botadmin.us.auth0.com";
  const clientId =
    process.env.REACT_APP_AUTH0_CLIENT_ID ?? "LpgCoihoREh1VEKtRuSq7PPr7PoIueSP";
  const redirect_uri = process.env.REACT_APP_URL ?? window.location.origin;

  const onRedirectCallback = (appState) => {
    console.log("Successful login");
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri,
        audience: "https://botadmin.bzlippi.ar/",
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
}
