const Pagination = ({
  postsPerPage,
  totalPosts,
  current,
  paginate,
  siblingCount = 2,
}) => {
  const pageNumbers = [];
  const totalPageCount = Math.ceil(totalPosts / postsPerPage ?? 1);

  for (
    let i = Math.max(current - siblingCount, 1);
    i <= Math.min(current + siblingCount, totalPageCount);
    i++
  ) {
    pageNumbers.push(i);
  }

  return (
    totalPosts > postsPerPage && (
      <nav>
        <ul className="pagination">
          {pageNumbers.map((number) => (
            <li key={number} className="page-item">
              <a
                onClick={() => paginate(number)}
                href="#"
                className={
                  current === number ? "page-link active" : "page-link"
                }
              >
                {number}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    )
  );
};

export default Pagination;
