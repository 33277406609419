import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Navbar";
import "../../css/Order.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faReceipt,
  faSave,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { createTicket } from "../../config/api";
import Select from "react-select";

export const blankOrder = {
  client: {
    name: "",
    clientId: "",
    _id: "",
    tel: "",
    cel: "",
    email: "",
    cuil: "",
  },
  products: [
    {
      id: 1,
      typeProduct: "",
      markProduct: "",
      model: "",
      serieNumber: "",
      defecto: "",
      presupuesto: "",
      issue: "",
      tecnico: "Claudio",
      status: "abierto",
    },
  ],
};

function AddOrder(props) {
  const [clients, setClients] = useState();
  const [order, setOrder] = useState(blankOrder);

  function addProduct() {
    const lastId = order.products[order.products.length - 1].id;
    const lastIndex = lastId + 1;
    setOrder((order) => ({
      ...order,
      products: [
        ...order.products,
        {
          ...blankOrder.products,
          id: lastIndex,
        },
      ],
    }));
    setTimeout(() => {
      document.getElementById("product-" + lastIndex).scrollIntoView();
    }, 30);
  }
  function removeProduct(index) {
    if (order.products.length === 1) return;
    function filter(e, id) {
      return e.filter((item) => item.id !== id);
    }
    setOrder((order) => ({
      ...order,
      products: filter(order.products, index),
    }));
  }

  const handleInput = (index) => (e) => {
    let newArr = [...order.products];
    newArr[index] = {
      ...order.products[index],
      [e.target.name]: e.target.value,
    };
    setOrder({
      ...order,
      products: newArr,
    });
  };
  const navigate = useNavigate();

  const onSend = async () => {
    console.log("send");
    await createTicket(order)
      .then((res) => {
        navigate("/order/" + res._id);
      })
      .catch(() => alert("Error saving order"));
  };

  const disableButton =
    order.client._id &&
    order.products.every(
      (p) => p.typeProduct && p.markProduct && p.model && p.serieNumber
    );

  return (
    <>
      <Navbar />
      <main>
        <div className="page-header">
          <h1> Agregar orden </h1>
          <FontAwesomeIcon
            icon={faReceipt}
            style={{ paddingRight: "3rem" }}
            size="3x"
          />
        </div>
        <hr />
        <div className="page-header">
          <h2 className="client-title">Datos del cliente</h2>
        </div>
        {order.client.name.length ? (
          <DatosCliente clients={clients} setOrder={setOrder} order={order} />
        ) : (
          <ClientSearch clients={clients} setOrder={setOrder} order={order} />
        )}
        {order.client.name && (
          <>
            {order.products.map((product, index) => {
              const detailsObs = product.obs
                ? product.details || "" + "\n------------\n" + product.obs
                : product.details;
              return (
                <>
                  <div className="page-header" id={`product-${product.id}`}>
                    <h2 className="client-title">Producto # {index + 1}</h2>
                    {index > 0 && (
                      <a
                        className="client-edit pointer"
                        onClick={() => removeProduct(product.id)}
                      >
                        <FontAwesomeIcon
                          className="delete"
                          icon={faTimes}
                          size="2x"
                        />
                      </a>
                    )}
                  </div>
                  <div className="client-grid">
                    <div>
                      <label id="tipo">Tipo de equipo</label>
                      <input
                        name="typeProduct"
                        onChange={handleInput(index)}
                        value={product.typeProduct}
                      />
                    </div>
                    <div>
                      <label id="marca">Marca</label>
                      <input
                        name="markProduct"
                        onChange={handleInput(index)}
                        value={product.markProduct}
                      />
                    </div>
                    <div>
                      <label id="modelo">Modelo</label>
                      <input
                        name="model"
                        onChange={handleInput(index)}
                        value={product.model}
                      />
                    </div>
                    <div>
                      <label id="num-serie">N° de Serie</label>
                      <input
                        name="serieNumber"
                        onChange={handleInput(index)}
                        value={product.serieNumber}
                      />
                    </div>
                    <div id="desc-prod">
                      <label>Descripción del producto / Observaciones</label>
                      <textarea
                        rows="3"
                        name="details"
                        onChange={handleInput(index)}
                        value={detailsObs}
                      />
                    </div>
                    <div id="defecto">
                      <label>Defecto</label>
                      <textarea
                        rows="3"
                        name="defecto"
                        onChange={handleInput(index)}
                        value={product.defecto}
                      />
                    </div>
                    {/* <div id="desc-pres">
                    <label>Descripción del presupuesto</label>
                    <textarea rows="3" name="presupuesto" onChange={handleInput(index)} value={product.presupuesto} />
                  </div>
                  <div id="res-tec">
                    <label>Resolución del técnico</label>
                    <textarea rows="3" name="issue" onChange={handleInput(index)} value={product.issue} />
                  </div> */}
                    <div id="tecnicoAdd">
                      <label>Técnico</label>
                      <select
                        value={product.tecnico}
                        name="tecnico"
                        id="tecnico-selector"
                        onChange={handleInput(index)}
                      >
                        <option value="claudio">Claudio</option>
                        <option value="hector">Héctor</option>
                        <option value="juan" hidden>
                          Juan
                        </option>
                      </select>
                    </div>
                    <div id="estadoAdd">
                      <label for="estado-selector">Estado</label>
                      <select
                        value={product.status}
                        name="status"
                        id="estado-selector"
                        onChange={handleInput(index)}
                      >
                        <option value="abierto">Abierto</option>
                        <option value="presupuestado">Presupuestado</option>
                        <option value="encurso">En curso</option>
                        <option value="cobrado">Cobrado</option>
                        <option value="finalizado">Finalizado</option>
                        <option value="cancelado">Cancelado</option>
                        <option value="contactar">Contactar al cliente</option>
                        <option value="pasado">Presupuesto pasado</option>
                        <option value="aprobado">Presupuesto aceptado</option>
                        <option value="rechazado">Presupuesto rechazado</option>
                      </select>
                    </div>
                  </div>
                  <br />
                  <hr />
                </>
              );
            })}
            <div className="add-footer">
              <button
                className="add"
                style={{ marginRight: "1rem" }}
                onClick={addProduct}
              >
                <FontAwesomeIcon icon={faPlus} /> Agregar producto
              </button>
              <button
                className="save"
                disabled={!disableButton}
                style={{ marginLeft: "1rem" }}
                onClick={onSend}
              >
                <FontAwesomeIcon icon={faSave} /> Guardar
              </button>
            </div>
          </>
        )}
      </main>
    </>
  );
}

function DatosCliente({ clients, setOrder, order }) {
  const clientsSearch = clients?.map((e) => {
    return {
      value: e.clientId,
      label: e.name,
    };
  });

  const setClient = (e) => {
    const clientF = clients.filter((cl) => cl.clientId === e.value);
    const client = clientF[0];
    console.log(e);
    console.log(client);
    setOrder({
      ...order,
      client: {
        _id: client._id,
        clientId: client.clientId,
        name: client.name,
        tel: client.tel,
        cel: client.cel,
        email: client.email,
        cuil: client.cuil ? client.cuil : "-",
      },
    });
    setTimeout(() => {
      console.log(order);
    }, 1000);
  };

  return (
    <div className="client-data">
      <div>
        <label id="nombre">Cliente </label>
        <Select
          value={order.client.name}
          options={clientsSearch}
          onChange={setClient}
          placeholder={order.client.name}
          openMenuOnClick={true}
          classNamePrefix="clientselector"
        />
      </div>
      <div>
        <label id="telefono">Teléfono</label>
        <input
          disabled
          placeholder={order.client.tel ? order.client.tel : "-"}
        />
      </div>
      <div>
        <label id="celular">Celular</label>
        <input
          disabled
          placeholder={order.client.cel ? order.client.cel : "-"}
        />
      </div>
      <div>
        <label id="email">Email</label>
        <input disabled placeholder={order.client.email} />
      </div>
      <div>
        <label id="email">CUIT / DNI</label>
        <input disabled placeholder={order.client.cuil} />
      </div>
    </div>
  );
}

function ClientSearch({ clients, setOrder, order }) {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const clientsSearch = clients?.map((e) => {
    return {
      value: e.clientId,
      label: e.name,
    };
  });

  const onClick = () => navigate("/customer/add");

  const setClient = (e) => {
    const clientF = clients.filter((cl) => cl.clientId === e.value);
    const client = clientF[0];
    console.log(e);
    console.log(clientF, client);
    setOrder((o) => ({
      ...o,
      client,
    }));
    setTimeout(() => {
      console.log(order);
    }, 1000);
  };

  return (
    <div className="client-data">
      <div>
        <label id="nombre">Cliente </label>
        <Select
          value={search}
          options={clientsSearch}
          onChange={setClient}
          placeholder="🔍 Ingrese el cliente"
          openMenuOnClick={false}
          classNamePrefix="clientselector"
        />
      </div>
      <div>
        <button
          className="save"
          style={{ marginTop: "1.8rem", cursor: "pointer" }}
          onClick={() => onClick()}
        >
          <FontAwesomeIcon icon={faPlus} /> Agregar cliente
        </button>
      </div>
    </div>
  );
}

export default AddOrder;
