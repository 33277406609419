import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ClientTable = ({ list, columns, link }) => {
  const navigateInstance = useNavigate();

  const navigate = (route) => {
    return navigateInstance(route);
  };

  const [hover, setHover] = useState(-1);
  const [iconHover, setIconHover] = useState(false);

  return (
    <div className="container">
      <ul className="client-table">
        <li className="table-header">
          {columns.map((col) => (
            <div className={"col " + col.type}>{col.name}</div>
          ))}
        </li>
        {list &&
          list.map((at, index) => {
            const borderLeftColor =
              hover === index ? "#5c8dff" : at.statusColor ?? "#ffffff";
            return (
              <li
                key={index}
                className="table-row"
                onMouseEnter={() => setHover(index)}
                onMouseLeave={() => setHover(-1)}
                href={link && at.id ? link + at.id : null}
                onClick={link ? () => navigate(link + at.id) : undefined}
                style={{
                  border: "1px solid #eeeeee00",
                  borderLeftWidth: "5px",
                  borderLeftColor,
                  backgroundColor: borderLeftColor + "1a",
                  cursor: link ? "pointer" : "auto",
                }}
              >
                {columns.map((col) => {
                  const {
                    type,
                    name,
                    action,
                    actionIcon,
                    actionIconColor,
                    actionHoverColor,
                    content,
                    contentStyle,
                  } = col;
                  return (
                    <div className={"col " + type} data-label={name}>
                      {action && actionIcon ? (
                        <FontAwesomeIcon
                          icon={actionIcon}
                          onClick={() => action(at.id)}
                          onMouseEnter={() => setIconHover(true)}
                          onMouseLeave={() => setIconHover(false)}
                          color={
                            iconHover && hover === index
                              ? actionHoverColor ?? "#6f99fc"
                              : actionIconColor ?? "#999"
                          }
                          size="1x"
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <a style={contentStyle}>{at[content] ?? "-"}</a>
                      )}
                    </div>
                  );
                })}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default ClientTable;
