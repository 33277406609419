import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Navigate } from "react-router-dom";
import Navbar from "../../Navbar";
import "../../css/Order.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReceipt, faSave, faUser } from "@fortawesome/free-solid-svg-icons";
import { compareObjects, formatDate } from "../../config/utils";
import { getTicketById, updateTicket } from "../../config/api";
import { Alert, Button, Form } from "react-bootstrap";

function Order(props) {
  const { id } = useParams();

  const [originalOrder, setOriginalOrder] = useState({});
  const [order, setOrder] = useState({
    products: [],
  });
  const [alert, setAlert] = useState({
    variant: "",
    content: undefined,
  });
  const [disableButton, setDisableButton] = useState(true);

  useEffect(() => {
    getTicketById(id).then((o) => {
      setOriginalOrder(JSON.parse(JSON.stringify(o)));
      setOrder(o);
    });
  }, [id]);

  const onProductChange = (e, index) => {
    const products = order.products;
    const product = products[index];
    product[e.target.name] = e.target.value;
    if (product.deliverDateProduct && e.target.name === "deliverDateProduct") {
      product.status = "entregado";
    }
    setOrder((o) => ({ ...o, products }));
  };

  useEffect(() => {
    const equality = compareObjects(order, originalOrder);
    setDisableButton(equality);
  }, [order]);

  const borders = (status) => {
    const cases = {
      entregado: { borderLeftColor: "var(--success)" },
      cobrado: { borderLeftColor: "var(--success)" },
      presupuestado: { borderLeftColor: "var(--info)" },
      aprobado: { borderLeftColor: "var(--accents)" },
      rechazado: { borderLeftColor: "var(--danger)" },
      finalizado: { borderLeftColor: "var(--warning)" },
      cancelado: { borderLeftColor: "var(--danger)" },
      default: { borderLeftColor: "var(--secondary)" },
    };
    return cases[status] || cases["default"];
  };

  const setAlertBanner = ({ variant, content, timer }) => {
    setAlert({
      variant,
      content,
    });
    window.scrollTo(0, 0);
    setTimeout(() => setAlert({ content: undefined }), timer ?? 6000);
  };

  const onSave = async () => {
    const closed = order.products.every(
      (product) =>
        product.status === "entregado" ||
        product.status === "cancelado" ||
        product.status === "cobrado"
    );
    if (closed) {
      if (!order.products.every((p) => p.deliverDateProduct)) {
        setAlertBanner({
          variant: "warning",
          content: "Por favor agregar la fecha de entrega.",
        });
        return undefined;
      } else {
        order.status = "close";
        order.closedDate = formatDate(
          Math.max.apply(
            null,
            order.products.map((e) => {
              return new Date(e.deliverDateProduct);
            })
          )
        );
      }
    }
    const update = await updateTicket(order)
      .then(() => {
        setAlertBanner({
          variant: "success",
          content: "Orden guardada correctamente.",
        });
      })
      .catch(() => {
        setAlertBanner({
          variant: "danger",
          content: "Error guardando la orden. Vuelva a intentarlo.",
        });
      });
    return update;
  };

  return (
    <>
      <Navbar />
      {order.client && (
        <main>
          <div className="page-header">
            <h1>
              {" "}
              Orden #{order.ticketId}{" "}
              {order.status === "open" ? (
                <div className="label primary">Abierta</div>
              ) : (
                <div className="label secondary">Cerrada</div>
              )}
            </h1>
            <FontAwesomeIcon
              icon={faReceipt}
              style={{ paddingRight: "1rem" }}
              size="3x"
            />
          </div>
          {alert.content && (
            <Alert variant={alert.variant} fade={true} transition={false}>
              {alert.content}
            </Alert>
          )}
          <hr />
          <div className="page-header">
            <h2 className="client-title">Datos del cliente</h2>
            <a
              href={
                "/customer/" + order.client.clientId + "/" + order.client._id
              }
              className="client-edit"
            >
              Ver cliente <FontAwesomeIcon className="icon" icon={faUser} />
            </a>
          </div>
          <div className="client-data">
            <div>
              <label id="nombre">Cliente</label>
              <input disabled placeholder={order.client.name} />
            </div>
            <div>
              <label id="telefono">Teléfono</label>
              <input
                disabled
                placeholder={order.client.tel ? order.client.tel : "-"}
              />
            </div>
            <div>
              <label id="celular">Celular</label>
              <input
                disabled
                placeholder={order.client.cel ? order.client.cel : "-"}
              />
            </div>
            <div>
              <label id="email">Email</label>
              <input disabled placeholder={order.client.email} />
            </div>
            <div>
              <label id="productos">Productos</label>
              <p>{order.products.length}</p>
            </div>
            <div>
              <label id="fecha">Fecha de Ingreso</label>
              <p>{formatDate(order.created_at)}</p>
            </div>
          </div>
          {order.products.map((product, index) => {
            const detailsObs = product.obs
              ? product.obs +
                (product.details ? "\n------------\n" + product.details : "")
              : product.details;
            return (
              <>
                <div className="page-header">
                  <h2 className="client-title">Producto # {index + 1}</h2>
                  <div>
                    <label htmlFor="estado-selector">Estado</label>
                    <select
                      value={product.status}
                      onChange={(e) => onProductChange(e, index)}
                      name="status"
                      id="estado-selector"
                    >
                      <option value="abierto">Abierto</option>
                      <option value="presupuestado">Presupuestado</option>
                      <option value="encurso" hidden>
                        En curso
                      </option>
                      <option value="finalizado">Finalizado</option>
                      <option value="entregado">Entregado</option>
                      <option value="cobrado">Cobrado</option>
                      <option value="cancelado">Cancelado</option>
                      <option value="contactar">Contactar al cliente</option>
                      <option value="pasado">Presupuesto pasado</option>
                      <option value="aprobado">Presupuesto aceptado</option>
                      <option value="rechazado">Presupuesto rechazado</option>
                    </select>
                  </div>
                </div>
                <div className="client-grid" style={borders(product.status)}>
                  <div>
                    <label id="tipo">Tipo de equipo</label>
                    <input
                      value={product.typeProduct}
                      name="typeProduct"
                      onChange={(e) => onProductChange(e, index)}
                    />
                  </div>
                  <div>
                    <label id="marca">Marca</label>
                    <input
                      value={product.markProduct}
                      name="markProduct"
                      onChange={(e) => onProductChange(e, index)}
                    />
                  </div>
                  <div>
                    <label id="modelo">Modelo</label>
                    <input
                      value={product.model}
                      name="model"
                      onChange={(e) => onProductChange(e, index)}
                    />
                  </div>
                  <div>
                    <label id="num-serie">N° de Serie</label>
                    <input
                      value={product.serieNumber}
                      name="serieNumber"
                      onChange={(e) => onProductChange(e, index)}
                    />
                  </div>
                  <div id="desc-prod">
                    <label>Descripción del producto / Observaciones</label>
                    <textarea
                      rows="3"
                      value={detailsObs}
                      name="details"
                      onChange={(e) => onProductChange(e, index)}
                    />
                  </div>
                  <div id="defecto">
                    <label>Defecto</label>
                    <textarea
                      rows="3"
                      value={product.defecto}
                      name="defecto"
                      onChange={(e) => onProductChange(e, index)}
                    />
                  </div>
                  <div id="desc-pres">
                    <label>Descripción del presupuesto</label>
                    <textarea
                      rows="3"
                      value={product.presupuesto}
                      name="presupuesto"
                      onChange={(e) => onProductChange(e, index)}
                    />
                  </div>
                  <div id="res-tec">
                    <label>Resolución del técnico</label>
                    <textarea
                      rows="3"
                      value={product.issue}
                      name="issue"
                      onChange={(e) => onProductChange(e, index)}
                    />
                  </div>
                  <div id="tecnico">
                    <label>Técnico</label>
                    <select
                      value={product.tecnico}
                      name="tecnico"
                      onChange={(e) => onProductChange(e, index)}
                      id="tecnico-selector"
                    >
                      <option value="claudio">Claudio</option>
                      <option value="hector">Héctor</option>
                      <option value="juan" hidden>
                        Juan
                      </option>
                    </select>
                  </div>
                  <div id="precio">
                    <label>Precio del presupuesto</label>
                    <div className="input-icon">
                      <input
                        type="text"
                        className="form-control ng-pristine ng-untouched ng-valid"
                        name="costo"
                        value={product.costo}
                        onChange={(e) => onProductChange(e, index)}
                      />
                      <i>$</i>
                    </div>
                  </div>
                  <div id="entrega">
                    <label>Fecha de entrega</label>
                    <input
                      type="date"
                      id="start"
                      value={order.deliverDateProduct}
                      name="deliverDateProduct"
                      onChange={(e) => onProductChange(e, index)}
                    />
                  </div>
                </div>
                <br />
                <hr />
              </>
            );
          })}
          <Button
            disabled={disableButton}
            style={{ marginLeft: "2rem" }}
            onClick={() => onSave()}
          >
            <FontAwesomeIcon icon={faSave} /> Guardar
          </Button>
        </main>
      )}
    </>
  );
}

export default Order;
