"use client";

import * as React from "react";
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

import { cn } from "../../lib/utils";
import { Button } from "./Button";
import { Calendar } from "./Calendar";
import { Popover, PopoverContent, PopoverTrigger } from "./Popover";
import moment from "moment";

export default function DatePickerWithRange({ className, date, setDate }) {
  const isAfterToday = (date) => (date.isAfter(moment()) ? moment() : date);

  const setFilter = (from, to) =>
    setDate({
      from: isAfterToday(from).hour(0).minutes(0).toDate(),
      to: isAfterToday(to).hour(0).minutes(0).toDate(),
    });
  const filters = [
    { name: "Hoy", onClick: () => setFilter(moment(), moment()) },
    {
      name: "Semana",
      onClick: () => setFilter(moment().day(1), moment().day(7)),
    },
    {
      name: "Semana pasada",
      onClick: () =>
        setFilter(
          moment().day(1).subtract(1, "week"),
          moment().day(7).subtract(1, "week")
        ),
    },
    {
      name: "Mes",
      onClick: () =>
        setFilter(
          moment().date(1),
          moment().date(1).add(1, "month").subtract("1", "day")
        ),
    },
    {
      name: "Mes pasado",
      onClick: () =>
        setFilter(
          moment().date(1).subtract(1, "month"),
          moment().date(1).subtract("1", "day")
        ),
    },
    {
      name: "Año",
      onClick: () => setFilter(moment().date(1).month(0), moment()),
    },
  ];
  return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "w-[300px] justify-start text-left font-normal bg-[#fefefe] border-gray-400/70",
              !date && "text-muted-foreground"
            )}
          >
            <CalendarIcon
              className="mr-2 h-4 w-4 text-gray-400"
              fill="lightgray"
            />

            <p className="text-gray-500 font-sans font-medium ml-1">
              {date?.from ? (
                date.to ? (
                  <span>
                    {format(date.from, "LLL dd, y")} -{" "}
                    {format(date.to, "LLL dd, y")}
                  </span>
                ) : (
                  format(date.from, "LLL dd, y")
                )
              ) : (
                <span>Elegir fecha</span>
              )}
            </p>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto bg-gray-50" align="start">
          <div className="-mt-3 -mb-2 px-3 flex justify-center space-x-2">
            {filters.map((b) => (
              <button
                onClick={b.onClick}
                className="bg-transparent rounded-3xl border-gray-400/70 text-gray-500 opacity-70 hover:opacity-100 font-medium hover:font-medium px-5"
              >
                {b.name}
              </button>
            ))}
          </div>
          <hr className="mb-5 mx-5 opacity-50" />
          <Calendar
            initialFocus
            mode="range"
            fromDate={moment().subtract(1, "year").toDate()}
            toDate={moment().add(1, "day").toDate()}
            defaultMonth={moment(date?.from ?? new Date())
              .subtract(1, "month")
              .toDate()}
            selected={date}
            onSelect={setDate}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
