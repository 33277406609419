import "../../css/Stats.css";
import React, { useEffect, useState } from "react";
import Navbar from "../../Navbar";
import {
  getNPSStats,
  getNPSEvaluationStats,
  getNPSMotives,
  getNPSCountPerDay,
  getAllQueues,
  getAgents,
  getNPSChats,
} from "../../config/api";
import { useAuth0 } from "@auth0/auth0-react";
import ChartPie from "../../Components/PieChart";
import StatCard from "../../Components/StatCard";
import DatePicker from "../../Components/ui/DataPicker";
import moment from "moment";
import { Search } from "lucide-react";
import BarChart from "../../Components/BarChart";
import { Gauge } from "../../Components/Gauge";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "Components/ui/Accordion";
import { Checkbox } from "Components/ui/Checkbox";
import { Spinner } from "Components/ui/Spinner";
import { AllChats } from "./AllChats";
import { parseQueue } from "config/utils";
import { parseChannel } from "config/utils";

function Stats(props) {
  const endOfMonth = moment()
    .date(1)
    .add(1, "month")
    .subtract(1, "day")
    .hour(0)
    .minutes(0);
  const now = moment().add(1, "day").hour(0).minutes(0);

  const [stats, setStats] = useState({
    NPS: {
      score: 0,
      average: 0,
      count: 0,
      detractors: 0,
      neutrals: 0,
      promoters: 0,
      perDay: [],
    },
    motives: {
      positive: [],
      negative: [],
    },
    evals: [],
    date: {
      from: moment().date(1).toDate(),
      to: endOfMonth.isAfter(now) ? now.toDate() : endOfMonth.toDate(),
    },
  });

  const [chats, setChats] = useState([]);

  const [filter, setFilter] = useState({
    queue: [],
    queueList: [],
    agent: [],
    agentList: [],
  });

  const setQueue = (queue) => {
    if (queue === "clear") {
      setFilter((f) => ({ ...f, queue: [] }));
    } else if (filter.queue.find((q) => q === queue)) {
      setFilter((f) => ({ ...f, queue: f.queue.filter((q) => q !== queue) }));
    } else {
      setFilter((f) => ({ ...f, queue: [...f.queue, queue] }));
    }
  };

  const setAgent = (agent) => {
    if (agent === "clear") {
      setFilter((f) => ({ ...f, agent: [] }));
    } else if (filter.agent.find((q) => q === agent)) {
      setFilter((f) => ({ ...f, agent: f.agent.filter((q) => q !== agent) }));
    } else {
      setFilter((f) => ({ ...f, agent: [...f.agent, agent] }));
    }
  };

  const setCalendarDate = (date) => {
    const from = date?.from;
    const to = !date?.to ? from : date.to;
    console.log("date", from, to);
    setStats((s) => ({ ...s, date: { from, to } }));
  };

  const { getAccessTokenSilently } = useAuth0();

  const getToken = async () => await getAccessTokenSilently();

  async function getDashboardItems() {
    try {
      await getToken().then(async (accessToken) => {
        setStats((s) => ({
          ...s,
          NPS: {
            score: 0,
            average: 0,
            count: 0,
            detractors: 0,
            neutrals: 0,
            promoters: 0,
            perDay: [],
          },
          motives: {
            positive: [],
            negative: [],
          },
          evals: [],
        }));
        await Promise.all([
          getNPSEvaluationStats(accessToken, {
            date: stats.date,
            agents: filter.agent,
            queues: filter.queue,
          })
            .then((data) => {
              setStats((s) => ({
                ...s,
                evals: data.map((v, i) => ({
                  name: v.value,
                  value: v.count,
                  color: COLORS[i % COLORS.length],
                })),
              }));
              return data;
            })
            .catch((e) => console.error("[getNPSEvaluationStats] Error: " + e)),
          getNPSStats(accessToken, {
            date: stats.date,
            agents: filter.agent,
            queues: filter.queue,
          })
            .then((data) => {
              setStats((s) => ({
                ...s,
                NPS: {
                  ...s.NPS,
                  score: data.NPS,
                  average: data.average,
                  count: data.count,
                  detractors: data.detractors.count,
                  neutrals: data.neutrals.count,
                  promoters: data.promoters.count,
                },
              }));
            })
            .catch((e) => console.error("[getNPSAverageStats] Error: " + e)),
          getNPSMotives(accessToken, {
            date: stats.date,
            agents: filter.agent,
            queues: filter.queue,
          })
            .then((data) => {
              setStats((s) => ({
                ...s,
                motives: {
                  positive: data.positive.counts.map((v, i) => ({
                    name: v.value,
                    value: v.count,
                    color: COLORS[i % COLORS.length],
                  })),
                  allPositiveMotives: data.positive.motives,
                  negative: data.negative.counts.map((v, i) => ({
                    name: v.value,
                    value: v.count,
                    color: COLORS[i % COLORS.length],
                  })),
                  allNegativeMotives: data.negative.motives,
                },
              }));
              return data;
            })
            .catch((e) => console.error("[getNPSEvaluationStats] Error: " + e)),
          getNPSCountPerDay(accessToken, {
            date: stats.date,
            agents: filter.agent,
            queues: filter.queue,
          })
            .then((data) => {
              setStats((s) => ({
                ...s,
                NPS: {
                  ...s.NPS,
                  perDay: data,
                },
              }));
              return data;
            })
            .catch((e) => console.error("[getNPSEvaluationStats] Error: " + e)),
        ]);
      });
    } catch (error) {
      console.error("[getDashboardItems] Error: " + error);
    }
  }

  async function getFilters() {
    try {
      await getToken().then((accessToken) => {
        Promise.all([getAllQueues(accessToken), getAgents(accessToken)])
          .then((data) => {
            setFilter((f) => ({
              ...f,
              queueList: data[0].map((v) => ({
                id: v.botmakerId,
                name: v.name,
              })),
              agentList: data[1].bm.map(({ id, name, email }) => ({
                id,
                name,
                email,
              })),
            }));
          })
          .catch((e) => console.error("[getFilters] Error: " + e));
      });
    } catch (e) {}
  }

  async function getChats() {
    try {
      setChats([]);
      await getToken().then((accessToken) => {
        getNPSChats(accessToken, {
          date: stats.date,
          agents: filter.agent,
          queues: filter.queue,
        })
          .then((data) => {
            const chats = [];

            for (const chat of data) {
              if (!chats.some((c) => c.id === chat.id && c.nps === chat.nps)) {
                chats.push({
                  ...chat,
                  queue: parseQueue(chat.queue),
                  date: chat.date.value,
                  motive:
                    stats.motives.allNegativeMotives.find(
                      (m) => m.id === chat.id && m.queue === chat.queue
                    )?.motive ||
                    stats.motives.allPositiveMotives.find(
                      (m) => m.id === chat.id && m.queue === chat.queue
                    )?.motive,
                  channel: parseChannel(chat.channel),
                });
              }
            }
            setChats(chats);
          })
          .catch((e) => console.error("[getChats] Error: " + e));
      });
    } catch (e) {}
  }

  useEffect(() => {
    getDashboardItems();
    getFilters();
  }, []);

  useEffect(() => {
    if (!stats.NPS.score) return; // Avoid at first load
    const getData = setTimeout(() => {
      getDashboardItems();
    }, 2000);

    return () => clearTimeout(getData);
  }, [filter.agent, filter.queue]);

  const COLORS = ["#0088FE", "#ffb617", "#FF8042", "#00C49F", "#8884d8"];

  return (
    <>
      <Navbar />
      <main className="p-0">
        <div className="page-header p-4">
          <h1 className="page-title">NPS</h1>
          <div className="ml-auto flex">
            <DatePicker
              date={stats.date}
              setDate={(date) => setCalendarDate(date)}
            />
            <button
              className="ml-1.5 mr-4 bg-transparent hover:bg-grey-800 border-gray-400/70"
              onClick={() => getDashboardItems()}
            >
              <Search
                className="h-5 w-5 mt-px mr-0.5 text-gray-400"
                fill="lightgray"
              />
            </button>

            <AllChats chats={chats} onClick={() => getChats()} />
          </div>
        </div>
        <hr className="ml-4 mr-6" />
        <div className="stats-page flex">
          <div className="cards-containers mx-auto">
            <div className="stats-container">
              <StatCard title="NPS">
                <div style={{ margin: "auto", textAlign: "center" }}>
                  <Gauge value={stats.NPS.score} />
                  <h1 className="text-7xl mt-0 mb-0">
                    {stats.NPS ? Number(stats.NPS.score) : "-"}
                  </h1>
                  <h4 className="mt-0">Score</h4>
                </div>
                <div className="mx-auto mt-2 text-center -mb-2">
                  <p className="text-xs mb-0 text-gray-500 font-semibold">
                    Promedio{" "}
                    <span className="text-black ml-1">
                      {(stats.NPS.average ?? 0).toFixed(1)}
                    </span>
                  </p>
                  <p className="text-xs mt-1 text-gray-500 font-semibold">
                    Cantidad{" "}
                    <span className="text-black ml-1">
                      {stats.NPS.count ?? 0}
                    </span>
                  </p>
                </div>
              </StatCard>
              <StatCard title="Composición NPS">
                <ChartPie
                  hollow
                  width={240}
                  height={320}
                  data={[
                    {
                      name: "Promotores",
                      value: stats.NPS.promoters,
                      color: "#0088FE",
                    },
                    {
                      name: "Neutrales",
                      value: stats.NPS.neutrals,
                      color: "#ffb617",
                    },
                    {
                      name: "Detractores",
                      value: stats.NPS.detractors,
                      color: "#FF8042",
                    },
                  ]}
                />
              </StatCard>
              <StatCard title="Evolución de respuestas">
                <div className="w-[570px] -mx-7 -mb-4 h-[300px]">
                  <BarChart
                    data={stats.NPS.perDay?.map((v) => ({
                      date: moment(v.date.value).format("DD-MM"),
                      count: v.count,
                      name: "Cantidad",
                    }))}
                  />
                </div>
              </StatCard>
            </div>

            <div className="stats-container">
              <StatCard title="Evaluación de atención">
                <ChartPie data={stats.evals} />
              </StatCard>
              <StatCard title="Buena experiencia">
                <ChartPie data={stats.motives.positive} hollow={false} />
              </StatCard>
              <StatCard title="Mala experiencia">
                <ChartPie data={stats.motives.negative} hollow={false} />
              </StatCard>
            </div>
          </div>
          <div className="filters bg-[#d7d7d7] rounded-lg shadow-inner mt-6 ml-auto -mr-2 pl-4 pr-5 w-[13%] min-w-44 max-w-64">
            <h3 className="text-center font-light">Filtros</h3>
            <hr className="mx-2" />
            <Accordion type="single" collapsible>
              <AccordionItem value="item-1" className="w-full">
                <AccordionTrigger className="w-auto hover:no-underline bg-slate-500 tracking-wider text-white border-white">
                  <div className="ml-2">
                    Cola de Atención
                    {!!filter.queue.length && (
                      <span className="ml-2 text-gray-300 rounded-full bg-gray-500 px-2 py-1">
                        {filter.queue.length}
                      </span>
                    )}
                  </div>
                </AccordionTrigger>
                <AccordionContent className="ml-2">
                  <p
                    className="mt-0 ml italic underline-offset-2 underline text-gray-400 cursor-pointer"
                    onClick={() => setQueue("clear")}
                  >
                    Limpiar filtro
                  </p>
                  {filter.queueList.length ? (
                    filter.queueList.map((queue) => (
                      <>
                        <div
                          className="flex items-center"
                          onClick={() => setQueue(queue.id)}
                        >
                          <Checkbox
                            checked={filter.queue.includes(queue.id)}
                            id={queue.id}
                          />
                          <label className="ml-2" htmlFor={queue.id}>
                            {queue.name}
                          </label>
                        </div>
                        <hr />
                      </>
                    ))
                  ) : (
                    <div className="mt-12 text-gray-500">
                      <Spinner>Cargando...</Spinner>
                    </div>
                  )}
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-2">
                <AccordionTrigger className="border-white hover:no-underline bg-slate-500 tracking-wider text-white">
                  <div className="ml-2">
                    Agentes
                    {!!filter.agent.length && (
                      <span className="ml-2 text-gray-300 rounded-full bg-gray-500 px-2 py-1">
                        {filter.agent.length}
                      </span>
                    )}
                  </div>
                </AccordionTrigger>
                <AccordionContent className="overflow-auto max-h-svh min-[1690px]:max-h-[575px] ml-2 pb-2">
                  <p
                    className="mt-0 ml italic underline-offset-2 underline text-gray-400 cursor-pointer"
                    onClick={() => setAgent("clear")}
                  >
                    Limpiar filtro
                  </p>
                  {filter.agentList.length ? (
                    filter.agentList.map((agent) => (
                      <>
                        <div
                          className="flex items-center"
                          onClick={() => setAgent(agent.id)}
                        >
                          <Checkbox
                            checked={filter.agent.includes(agent.id)}
                            id={agent.id}
                          />
                          <label
                            className="ml-2"
                            title={agent.email}
                            htmlFor={agent.id}
                          >
                            {agent.name}
                          </label>
                        </div>
                        <hr />
                      </>
                    ))
                  ) : (
                    <div className="mt-12 text-gray-500">
                      <Spinner>Cargando...</Spinner>
                    </div>
                  )}
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </main>
    </>
  );
}

export default Stats;
