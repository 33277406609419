import Navbar from "../../Navbar";
import "../../css/Dashboard.css";
import { queues as queuesList } from "../../config/utils";
import { faClipboardList, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { createUser, getAllUsers } from "../../config/api";
import ClientTable from "../../Components/ClientTable";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "../../Components/ui/Button";
import { Modal } from "../../Components/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "Components/ui/Sheet";

function Config() {
  const [agents, setAgents] = useState();

  const [isModalOpen, setModalIsOpen] = useState(false);
  const [newUser, setNewUser] = useState({
    email: "",
    password: "",
  });

  const toggleModal = () => {
    console.log("toggle modal", isModalOpen);
    setModalIsOpen(!isModalOpen);
  };

  const { getAccessTokenSilently } = useAuth0();

  const getToken = async () => await getAccessTokenSilently();

  async function getUsers() {
    try {
      await getToken().then((accessToken) => {
        getAllUsers(accessToken)
          .then((data) => {
            setAgents(
              data.map((agent) => ({
                ...agent,
                fullName: `${agent.firstName} ${agent.lastName ?? ""}`,
              }))
            );
            return data;
          })
          .catch((e) => console.error("[fetchOrdersQty] Error: " + e));
      });
    } catch (error) {
      console.error("[getUsers] Error: " + error);
    }
  }

  useEffect(() => {
    getUsers();
  }, [isModalOpen]);

  const columns = [
    ,
    {
      name: "Usuario",
      type: "col-2",
      content: "fullName",
    },
    {
      name: "Email",
      type: "col-3",
      content: "email",
    },
    {
      name: "Rol",
      type: "col-2",
      content: "role",
    },
    /*{
      name: "",
      type: "col-1",
      action: (id) => alert(id),
      actionIcon: faPencilAlt,
    },*/
  ];

  const { getIdTokenClaims } = useAuth0();

  const [isTester, setTester] = useState(false);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "OPERATOR",
    botmaker: true,
    botadmin: false,
    queues: queuesList
      .map((queue) => ({
        [queue.name]: false,
      }))
      .reduce((r, c) => ({ ...r, ...c }), {}),
  });
  const handleInput = (e) => {
    if (e.target.name === "botmaker" && user.botadmin) {
      return setUser((u) => ({
        ...u,
        botmaker: !u.botmaker,
        botadmin: false,
      }));
    }
    if (e.target.type === "checkbox") {
      return setUser((u) => ({
        ...u,
        [e.target.name]: !u[e.target.name],
      }));
    }
    setUser((u) => ({ ...u, [e.target.name]: e.target.value }));
  };
  const handleQueues = (e) => {
    setUser((u) => ({
      ...u,
      queues: { ...u.queues, [e.target.name]: !u.queues[e.target.name] },
    }));
  };

  useEffect(() => {
    const getToken = async () =>
      await getIdTokenClaims().then((claims) => {
        if (claims["userRoles"].includes("Tester")) {
          setTester(true);
        }
      });
    getToken();
  }, [getIdTokenClaims]);

  return (
    <>
      <Navbar />
      <main>
        {isModalOpen &&
          (!newUser.email ? (
            <AddUserModal
              onActionButton={(user) =>
                createUserAccount(user, getToken, setNewUser)
              }
              toggleModal={toggleModal}
            />
          ) : (
            <NewUserModal
              closeModal={() => {
                toggleModal();
                setNewUser({});
              }}
              user={newUser}
            />
          ))}
        <div className="page-header">
          <h1 className="page-title">Configuración</h1>
        </div>
        <hr />
        <div className="dashboard-container">
          <Sheet>
            <SheetTrigger>Open</SheetTrigger>
            <SheetContent>
              <SheetHeader>
                <SheetTitle>Agregar usuario</SheetTitle>
                <SheetDescription>
                  Podes agregar agentes a la plataforma de Botmaker y tambien
                  para BotAdmin.
                </SheetDescription>
                <div className="pl-1 pr-4">
                  <div style={{ width: "100%" }}>
                    <br />
                    <label id="firstName">Nombre</label>
                    <input
                      value={user.firstName}
                      name="firstName"
                      onChange={handleInput}
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <br />
                    <label id="lastName">Apellido</label>
                    <input
                      value={user.lastName}
                      name="lastName"
                      onChange={handleInput}
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <br />
                    <label id="role">Rol</label>
                    <select
                      value={user.role}
                      name="role"
                      onChange={handleInput}
                      style={{ width: "100%" }}
                    >
                      <option>OPERATOR</option>
                      <option>SUPERVISOR</option>
                      <option>ADMIN</option>
                    </select>
                  </div>
                  <div style={{ width: "100%" }}>
                    <br />
                    <label id="email">Email</label>
                    <input
                      name="email"
                      value={user.email}
                      onChange={handleInput}
                      style={{ width: "100%" }}
                    />
                    <p style={{ opacity: 0.4, fontSize: 12 }}>
                      La contraseña será generada automáticamente.
                    </p>
                  </div>

                  <div>
                    <label id="queues" style={{ marginBottom: "15px" }}>
                      Colas
                    </label>
                    {queuesList.map((queue, i) => (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          marginTop: "10px",
                        }}
                      >
                        <input
                          name={queue.name}
                          type="checkbox"
                          checked={user.queues[queue.name]}
                          onChange={handleQueues}
                          style={{
                            width: "25px",
                            marginTop: 1,
                            alignItems: "baseline",
                          }}
                        />
                        <p
                          onClick={() =>
                            handleQueues({ target: { name: queue.name } })
                          }
                          style={{ margin: 0 }}
                        >
                          {queue.name}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div>
                    <br />
                    <label id="addTo">Agregar a</label>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        marginTop: "15px",
                      }}
                    >
                      <input
                        name="botmaker"
                        type="checkbox"
                        checked={user.botmaker}
                        onChange={handleInput}
                        style={{
                          width: "25px",
                          marginTop: 2.5,
                          alignItems: "baseline",
                        }}
                      />
                      <p
                        onClick={() =>
                          handleInput({
                            target: { name: "botmaker", value: !user.botmaker },
                          })
                        }
                        style={{ margin: 0 }}
                      >
                        Botmaker
                      </p>
                      <input
                        name="botadmin"
                        type="checkbox"
                        disabled={!isTester && !user.botmaker}
                        checked={user.botadmin}
                        onChange={handleInput}
                        style={{
                          width: "25px",
                          marginTop: 2.5,
                          alignItems: "baseline",
                          marginLeft: "1rem",
                        }}
                      />
                      <p
                        onClick={() =>
                          user.botmaker &&
                          handleInput({
                            target: { name: "botadmin", value: !user.botadmin },
                          })
                        }
                        style={{
                          margin: 0,
                          opacity: !isTester && !user.botmaker ? 0.5 : 1,
                        }}
                      >
                        BotAdmin
                      </p>
                    </div>
                  </div>
                  <div className="mt-3">
                    <Button
                      onClick={createUserAccount(user, getToken, setNewUser)}
                    >
                      Guardar
                    </Button>
                    <Button className="ml-2 text-gray-700" variant={"outline"}>
                      Cancelar
                    </Button>
                  </div>
                </div>
              </SheetHeader>
            </SheetContent>
          </Sheet>
          <div className="dashboard-table">
            <div className="client-title" style={{ padding: 0 }}>
              <h2>Usuarios</h2>
              <div className="client-actions">
                <Button
                  onClick={() => toggleModal()}
                  style={{ backgroundColor: "green" }}
                >
                  <FontAwesomeIcon className="mr-1.5" icon={faPlus} /> Agregar
                </Button>
              </div>
            </div>
            <hr style={{ margin: 0 }} />
            {agents && <ClientTable list={agents} columns={columns} />}
          </div>
        </div>
      </main>
    </>
  );
}
const createUserAccount = async (user, getToken, setNewUser) => {
  const accessToken = await getToken();
  const queues = Object.entries(user.queues)
    .filter(([q, x]) => !!x)
    .map(([q, x]) => {
      const queue = queuesList.find((qs) => qs.name === q);
      return queue.botmakerId;
    });
  const newUser = await createUser({
    ...user,
    queues,
    accessToken,
  }).then((user) => {
    setNewUser(user.botmakerUser ?? { email: null, password: null });
  });
  return newUser;
};

function AddUserModal({ toggleModal, onActionButton }) {
  const { getIdTokenClaims } = useAuth0();

  const [isTester, setTester] = useState(false);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "OPERATOR",
    botmaker: true,
    botadmin: false,
    queues: queuesList
      .map((queue) => ({
        [queue.name]: false,
      }))
      .reduce((r, c) => ({ ...r, ...c }), {}),
  });
  const handleInput = (e) => {
    if (e.target.name === "botmaker" && user.botadmin) {
      return setUser((u) => ({
        ...u,
        botmaker: !u.botmaker,
        botadmin: false,
      }));
    }
    if (e.target.type === "checkbox") {
      return setUser((u) => ({
        ...u,
        [e.target.name]: !u[e.target.name],
      }));
    }
    setUser((u) => ({ ...u, [e.target.name]: e.target.value }));
  };
  const handleQueues = (e) => {
    setUser((u) => ({
      ...u,
      queues: { ...u.queues, [e.target.name]: !u.queues[e.target.name] },
    }));
  };

  useEffect(() => {
    const getToken = async () =>
      await getIdTokenClaims().then((claims) => {
        if (claims["userRoles"].includes("Tester")) {
          setTester(true);
        }
      });
    getToken();
  }, [getIdTokenClaims]);

  return (
    <Modal
      title={"Agregar usuario"}
      closeButtonText={"Cerrar"}
      onRequestClose={toggleModal}
      actionButtonText={"Agregar"}
      onActionButton={() => onActionButton(user)}
    >
      <div style={{ width: "100%" }}>
        <br />
        <label id="firstName">Nombre</label>
        <input
          value={user.firstName}
          name="firstName"
          onChange={handleInput}
          style={{ width: "100%" }}
        />
      </div>
      <div style={{ width: "100%" }}>
        <br />
        <label id="lastName">Apellido</label>
        <input
          value={user.lastName}
          name="lastName"
          onChange={handleInput}
          style={{ width: "100%" }}
        />
      </div>
      <div style={{ width: "100%" }}>
        <br />
        <label id="role">Rol</label>
        <select
          value={user.role}
          name="role"
          onChange={handleInput}
          style={{ width: "100%" }}
        >
          <option>OPERATOR</option>
          <option>SUPERVISOR</option>
          <option>ADMIN</option>
        </select>
      </div>
      <div style={{ width: "100%" }}>
        <br />
        <label id="email">Email</label>
        <input
          name="email"
          value={user.email}
          onChange={handleInput}
          style={{ width: "100%" }}
        />
        <p style={{ opacity: 0.4, fontSize: 12 }}>
          La contraseña será generada automáticamente.
        </p>
      </div>

      <div>
        <label id="queues" style={{ marginBottom: "15px" }}>
          Colas
        </label>
        {queuesList.map((queue, i) => (
          <div
            style={{
              width: "100%",
              display: "flex",
              marginTop: "10px",
            }}
          >
            <input
              name={queue.name}
              type="checkbox"
              checked={user.queues[queue.name]}
              onChange={handleQueues}
              style={{
                width: "25px",
                marginTop: 1,
                alignItems: "baseline",
              }}
            />
            <p
              onClick={() => handleQueues({ target: { name: queue.name } })}
              style={{ margin: 0 }}
            >
              {queue.name}
            </p>
          </div>
        ))}
      </div>
      <div>
        <br />
        <label id="addTo">Agregar a</label>
        <div style={{ width: "100%", display: "flex", marginTop: "15px" }}>
          <input
            name="botmaker"
            type="checkbox"
            checked={user.botmaker}
            onChange={handleInput}
            style={{
              width: "25px",
              marginTop: 2.5,
              alignItems: "baseline",
            }}
          />
          <p
            onClick={() =>
              handleInput({
                target: { name: "botmaker", value: !user.botmaker },
              })
            }
            style={{ margin: 0 }}
          >
            Botmaker
          </p>
          <input
            name="botadmin"
            type="checkbox"
            disabled={!isTester && !user.botmaker}
            checked={user.botadmin}
            onChange={handleInput}
            style={{
              width: "25px",
              marginTop: 2.5,
              alignItems: "baseline",
              marginLeft: "1rem",
            }}
          />
          <p
            onClick={() =>
              user.botmaker &&
              handleInput({
                target: { name: "botadmin", value: !user.botadmin },
              })
            }
            style={{
              margin: 0,
              opacity: !isTester && !user.botmaker ? 0.5 : 1,
            }}
          >
            BotAdmin
          </p>
        </div>
      </div>
    </Modal>
  );
}

function NewUserModal({ closeModal, user }) {
  const { email, password } = user;

  const [copy, setCopy] = useState(false);

  useEffect(() => setCopy(false), [password]);

  const handleCopy = () => {
    navigator.clipboard.writeText(password);
    setCopy(true);
  };

  return (
    <Modal
      title={"Usuario creado"}
      closeButtonText={"Cerrar"}
      onRequestClose={() => closeModal()}
    >
      <div style={{ width: "100%" }}>
        <label id="email">Email</label>
        <input disabled value={email ?? "-"}></input>
      </div>
      <br />
      <div style={{ width: "100%" }}>
        <label id="email">Contraseña</label>
        <div style={{ display: "flex" }}>
          <input disabled value={password ?? "-"}></input>
          <div style={{ top: "30px" }}>
            <Button
              style={{ backgroundColor: "#999", margin: 10 }}
              title={"Copiar al portapapeles"}
              onClick={() => handleCopy()}
            >
              <FontAwesomeIcon icon={faClipboardList} size="1x" />
            </Button>
          </div>
        </div>
      </div>
      <p style={{ opacity: 0.4, fontSize: 12 }}>
        {!copy
          ? "Esta contraseña se envió por email al nuevo usuario. (Prontamente)"
          : "Copiado al portapapeles."}
      </p>
    </Modal>
  );
}

export default Config;
