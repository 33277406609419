import "./Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartPie,
  faCogs,
  faComments,
  faHome,
  faPowerOff,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

const Navbar = () => {
  const { logout, getIdTokenClaims } = useAuth0();
  const navigateInstance = useNavigate();
  const [tester, setTester] = useState(false);
  const [user, setUser] = useState({ name: "" });

  const navigate = (route, e) => {
    if (e) e.preventDefault();
    return navigateInstance(route);
  };

  const handleLogout = () =>
    logout({ logoutParams: { returnTo: window.location.origin } });

  useEffect(() => {
    const getToken = async () =>
      await getIdTokenClaims().then((claims) => {
        console.log(claims);
        setUser(claims);
        if (claims["userRoles"].includes("Tester")) {
          setTester(true);
        } else setTester(false);
        return claims;
      });

    getToken();
  }, [getIdTokenClaims]);
  return (
    <nav className="navbar" id="site-left-navbar">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-brand">
            <span>Bot</span>
            <span>Admin</span>
            <a className="os-desc">Express</a>
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#"
            className="nav-link"
            onClick={(e) => navigate("/home", e)}
          >
            <FontAwesomeIcon size="lg" icon={faHome} />
            <span className="link-text">Inicio</span>
          </a>
        </li>
        {tester && (
          <li className="nav-item">
            <a
              href="#"
              className="nav-link"
              onClick={() => navigate("/attentions")}
            >
              <FontAwesomeIcon size="lg" icon={faComments} />
              <span className="link-text">Atenciones</span>
            </a>
          </li>
        )}
        <li className="nav-item">
          <a
            href="#"
            className="nav-link"
            onClick={(e) => navigate("/clients", e)}
          >
            <FontAwesomeIcon size="lg" icon={faUsers} />
            <span className="link-text">Clientes</span>
          </a>
        </li>
        {/*
        <li className="nav-item">
          <a
            href="/sales"
            className="nav-link"
            onClick={() => navigate("/sales")}
          >
            <FontAwesomeIcon size="lg" icon={faHandshake} />
            <span className="link-text">Ventas</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            href="/money"
            className="nav-link"
            onClick={() => navigate("/money")}
          >
            <FontAwesomeIcon size="lg" icon={faWallet} />
            <span className="link-text">Caja</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            href="/config"
            className="nav-link"
            onClick={() => navigate("/config")}
          >
            <FontAwesomeIcon size="lg" icon={faCogs} />
            <span className="link-text">Configuración</span>
          </a>
        </li>
      */}

        <li className="nav-item">
          <a
            href="/stats"
            className="nav-link"
            onClick={() => navigate("/stats")}
          >
            <FontAwesomeIcon size="lg" icon={faChartPie} />
            <span className="link-text">NPS</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            href="/config"
            className="nav-link"
            onClick={() => navigate("/config")}
          >
            <FontAwesomeIcon size="lg" icon={faCogs} />
            <span className="link-text">Configuración</span>
          </a>
        </li>
        <li className="nav-item" onClick={() => handleLogout()}>
          <a style={{ cursor: "pointer" }} className="nav-link">
            <FontAwesomeIcon size="lg" icon={faPowerOff} />
            <span className="link-text">Log out</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
