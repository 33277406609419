import {
  faExclamationTriangle,
  faRobot,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../css/Login.css";

const Login = (props, { setLogged }) => {
  return (
    <div id="login-div">
      <br />
      <br />
      <form id="login-form">
        <h1 id="title">
          <FontAwesomeIcon icon={faRobot} /> BotAdmin
        </h1>

        <br />
        <br />
        <br />
        <FontAwesomeIcon icon={faExclamationTriangle} size="4x" />
        <h2>Pagina no existente.</h2>
        <br />
        <br />
        <br />
        <a href="/home" style={{ color: "#5c8dff", opacity: 0.7 }}>
          Volver al Inicio
        </a>
        <br />
      </form>
      <div className="footer">
        <p>BotAdmin - Ilubot</p>
      </div>
    </div>
  );
};

export default Login;
