import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";

const ChartPie = ({ data, hollow, width = 300, height = 270 }) => {
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 2.7;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  return (
    <PieChart width={width} height={height} className="m-auto -mt-5">
      <Pie
        data={data}
        innerRadius={hollow && 60}
        outerRadius={80}
        fill="#8884d8"
        label={hollow ? true : renderCustomizedLabel}
        labelLine={hollow ? true : false}
        paddingAngle={hollow && 5}
        legendType="circle"
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} dataKey={entry} fill={entry.color} />
        ))}
      </Pie>
      <Tooltip label={"name"} content={<CustomTooltip />} />
      <Legend className="mt-auto" />
    </PieChart>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    console.log("payload", payload);
    return (
      <div className="bg-slate-700/70 rounded-sm px-3">
        <p className="text-sm text-gray-200 tracking-wide font-semibold py-1">
          {payload[0].name}:{" "}
          <span className="text-white font-bold text-lg">
            {payload[0].value}
          </span>
        </p>
      </div>
    );
  }

  return null;
};

export default ChartPie;
