import { parseDni } from "../config/utils";

export const CustomerData = ({ customer, edit = true }) => {
  return (
    <div className="client-data">
      <div>
        <label id="nombre">Nombre</label>
        <input disabled={edit} value={customer?.firstName}></input>
      </div>
      <div>
        <label id="apellido">Apellido</label>
        <input disabled={edit} value={customer?.lastName} />
      </div>
      <div>
        <label id="sucursal">Sucursal</label>
        <input disabled={edit} value={customer?.location?.name ?? "-"} />
      </div>
      <div>
        <label id="telefono">Teléfono</label>
        <input disabled={edit} value={customer?.tel ?? "-"} />
      </div>

      <div>
        <label id="email">Email</label>
        <input disabled={edit} value={customer?.business?.email ?? "-"} />
      </div>
      <div>
        <label id="cuil-cuit">DNI/CUIT</label>
        <input
          disabled={edit}
          value={customer?.internalId ? parseDni(customer.internalId) : "-"}
        />
      </div>
      <div>
        <label id="status">Estado</label>
        <input
          disabled={edit}
          value={customer?.business?.state ?? "Desconocido"}
        />
      </div>
      <div>
        <label id="deuda">Deuda</label>
        <input
          disabled={edit}
          value={
            customer?.business?.paymentTotal
              ? "$" + customer?.business?.paymentTotal
              : "-"
          }
        />
      </div>
    </div>
  );
};
