import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import "../css/Modal.css";

export const Modal = ({
  onRequestClose,
  title,
  closeButtonText,
  children,
  actionButtonText,
  onActionButton,
}) => {
  useEffect(() => {
    function onKeyDown(event) {
      // Escape key
      if (event.keyCode === 27) {
        onRequestClose();
      }
    }

    document.body.style.overflow = "hidden";
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.body.style.overflow = "visible";
      document.removeEventListener("keydown", onKeyDown);
    };
  });

  return (
    <div className="modal__backdrop">
      <div className="modal__container">
        <h2 className="modal__title">
          <b>{title}</b>
          <div className="modal__close_button" onClick={onRequestClose}>
            <FontAwesomeIcon style={{ color: "#aeaeae" }} icon={faTimes} />
          </div>
        </h2>
        <div className="modal__children">{children}</div>
        <div className="modal__buttons">
          <button
            type="button"
            style={{ backgroundColor: "#aeaeae" }}
            onClick={onRequestClose}
          >
            {closeButtonText}
          </button>
          {actionButtonText && (
            <button
              type="button"
              style={{ backgroundColor: "green" }}
              onClick={() => onActionButton()}
            >
              {actionButtonText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
