import { Spinner } from "Components/ui/Spinner";
import { Button } from "../../Components/ui/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../Components/ui/Dialog";
import { DataTable } from "Components/DataTable";
import { columns } from "./ChatsColumns";

export function AllChats({ chats, onClick }) {
  const loading = !chats?.length;
  return (
    <Dialog>
      <DialogTrigger onClick={onClick} asChild>
        <Button
          variant="outline"
          className="bg-gray-500 hover:bg-gray-600 hover:text-white mb-0"
        >
          Ver chats
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Chats</DialogTitle>
          <DialogDescription>Hasta 50 chats disponibles.</DialogDescription>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <div className="grid flex-1 gap-2">
            {loading ? (
              <div className="flex items-center justify-center w-full h-24">
                <Spinner>Cargando...</Spinner>
              </div>
            ) : (
              <DataTable columns={columns} data={chats} />
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
