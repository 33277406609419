import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { PrivateRoute } from "./Pages/PrivateRoute";
import Dashboard from "./Pages/Dashboard";
import Customers from "./Pages/Clients";
import AddOrder from "./Pages/Clients/AddClient";
import Order from "./Pages/Clients/OrderId";
import AddClient from "./Pages/Clients/AddClient";
import Client from "./Pages/Clients/ClientId";
import PageNotFound from "./Pages/PageNotFound";
import AttentionDetail from "./Pages/Attentions/AttentionDetail";
import Attentions from "./Pages/Attentions";
import SessionDetail from "./Pages/Sessions/SessionDetail";
import AuthProvider from "./AuthProvider";
import Config from "./Pages/Config";
import Stats from "./Pages/Stats";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Stats />
              </PrivateRoute>
            }
          />
          <Route
            path="/home"
            element={
              <PrivateRoute>
                <Stats />
              </PrivateRoute>
            }
          />
          <Route
            path="/clients"
            element={
              <PrivateRoute>
                <Customers />
              </PrivateRoute>
            }
          />
          <Route
            path="/order/add"
            element={
              <PrivateRoute>
                <AddOrder />
              </PrivateRoute>
            }
          />
          <Route
            path="/order/:id"
            element={
              <PrivateRoute>
                <Order />
              </PrivateRoute>
            }
          />
          <Route
            path="/attentions"
            element={
              <PrivateRoute>
                <Attentions />
              </PrivateRoute>
            }
          />
          <Route
            path="/attention/:id"
            element={
              <PrivateRoute>
                <AttentionDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/customer/add"
            element={
              <PrivateRoute>
                <AddClient />
              </PrivateRoute>
            }
          />
          <Route
            path="/customer/:id"
            element={
              <PrivateRoute>
                <Client />
              </PrivateRoute>
            }
          />
          <Route
            path="/session/:id"
            element={
              <PrivateRoute>
                <SessionDetail />
              </PrivateRoute>
            }
          />

          <Route
            path="/nps"
            element={
              <PrivateRoute>
                <Stats />
              </PrivateRoute>
            }
          />
          <Route
            path="/stats"
            element={
              <PrivateRoute>
                <Stats />
              </PrivateRoute>
            }
          />
          <Route
            path="/config"
            element={
              <PrivateRoute>
                <Config />
              </PrivateRoute>
            }
          />
          <Route
            path="/*"
            element={
              <PrivateRoute>
                <PageNotFound />
              </PrivateRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
