import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserNotAllowed from "./UserNotAllowed";

export function PrivateRoute({ children, requiredRoles = ["user"] }) {
  const { loginWithRedirect, isAuthenticated, isLoading, getIdTokenClaims } =
    useAuth0();
  const [getClaims, setClaims] = useState(); // sacar []

  useEffect(() => {
    if (isLoading) return;
    const getToken = async () =>
      await getIdTokenClaims().then((claims) => {
        console.log(claims);
        setClaims(claims ? claims["userRoles"] : undefined);
        return claims;
      });

    getToken();
    if (!isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
        },
      });
    }
  }, [isLoading, isAuthenticated]);

  /* if (getClaims && !requiredRoles.every((rr) => getClaims.includes(rr))) {
    return <UserNotAllowed />;
  } */
  return isAuthenticated && children;
}
