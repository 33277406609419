import { ArrowUpDown, SquareArrowOutUpRight } from "lucide-react";

export const columns = [
  {
    accessorKey: "date",
    header: ({ column }) => (
      <div
        className="text-center cursor-pointer"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      >
        Fecha <ArrowUpDown className="ml-2 h-4 w-4" />
      </div>
    ),
    cell: ({ row }) => {
      return <div className="text-center">{row.getValue("date")}</div>;
    },
  },
  {
    accessorKey: "queue",
    header: ({ column }) => (
      <div
        className="text-center cursor-pointer"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      >
        Cola <ArrowUpDown className="ml-2 h-4 w-4" />
      </div>
    ),
    cell: ({ row }) => {
      return <div className="text-center">{row.getValue("queue")}</div>;
    },
  },
  {
    accessorKey: "operator_name",
    header: ({ column }) => (
      <div
        className="text-center cursor-pointer"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      >
        Agente <ArrowUpDown className="ml-2 h-4 w-4" />
      </div>
    ),
    cell: ({ row }) => {
      return <div className="text-center">{row.getValue("operator_name")}</div>;
    },
  },
  {
    accessorKey: "channel",
    header: ({ column }) => (
      <div
        className="text-center cursor-pointer"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      >
        Canal <ArrowUpDown className="ml-2 h-4 w-4" />
      </div>
    ),
    cell: ({ row }) => {
      return <div className="text-center">{row.getValue("channel")}</div>;
    },
  },
  {
    accessorKey: "nps",
    header: ({ column }) => (
      <div
        className="text-center cursor-pointer"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      >
        NPS <ArrowUpDown className="ml-2 h-4 w-4" />
      </div>
    ),
    cell: ({ row }) => {
      return <div className="text-center">{row.getValue("nps")}</div>;
    },
  },
  {
    accessorKey: "motive",
    header: ({ column }) => (
      <div
        className="text-center cursor-pointer"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      >
        Motivo <ArrowUpDown className="ml-2 h-4 w-4" />
      </div>
    ),
    cell: ({ row }) => {
      return <div className="text-center">{row.getValue("motive") ?? "-"}</div>;
    },
  },
  {
    accessorKey: "actions",
    header: "Ver chat",
    cell: ({ row }) => {
      return (
        <div className="text-center">
          <a
            target="blank"
            href={"https://go.botmaker.com/#/chats/" + row.original.id}
          >
            <SquareArrowOutUpRight className="h-5 w-5 mx-4 text-gray-400" />
          </a>
        </div>
      );
    },
  },
];
