import Navbar from "../../Navbar";
import "../../css/Table.css";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClientTable from "../../Components/ClientTable";
import { getAllCustomers, searchCustomers } from "../../config/api";
import { locations, parseDni } from "../../config/utils";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";
import Pagination from "../../Components/Pagination";

export const attentionStatus = {
  IN_PROCESS: "Atendido",
  WAITING: "Esperando",
  FINISHED: "Finalizada",
  TRANSFERED: "Transferido",
};

function Clients() {
  const [clientList, setClients] = useState([]);
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState("");
  const [filter, setFilter] = useState();

  const [pagination, setPagination] = useState({
    currentPage: 1,
    postsPerPage: 10,
    totalPosts: 10,
  });

  console.log(pagination);

  const paginate = (currentPage) => {
    setPagination({
      ...pagination,
      currentPage,
    });
  };

  const filterFn = (filter) => {
    if (filter === "none") {
      return setFilter();
    }
    setFilter(filter);
    setPagination({
      ...pagination,
      currentPage: 1,
      totalPosts: 0,
    });
    setClients([]);
  };

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (search) {
      const getData = setTimeout(async () => {
        const accessToken = await getAccessTokenSilently();
        searchCustomers({
          take: pagination.postsPerPage,
          query: search,
          skip: (pagination.currentPage - 1) * pagination.postsPerPage,
          accessToken,
        }).then((data) => {
          const tableData = data.items.map((client) => ({
            ...client,
            locationName: client.location.name,
            fullName: client.firstName + " " + (client.lastName ?? ""),
            dniCedula: client.internalId ? parseDni(client.internalId) : "-",
            statusColor: "#eeeeee",
          }));
          setSearchResults(tableData);
          console.log("data", tableData);
          setPagination({
            ...pagination,
            totalPosts: data.count,
          });
        });
      }, 2000);
      return () => clearTimeout(getData);
    }
    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        getAllCustomers({
          take: pagination.postsPerPage,
          filter,
          skip: (pagination.currentPage - 1) * pagination.postsPerPage,
          accessToken,
        }).then((data) => {
          const tableData = data.items.map((client) => ({
            ...client,
            locationName: client.location.name,
            fullName: client.fullName,
            dniCedula: client.internalId ? parseDni(client.internalId) : "-",
            statusColor: "#eeeeee",
          }));
          setClients(tableData);
          console.log("data", tableData);
          setPagination({
            ...pagination,
            totalPosts: data.count,
          });
        });
      } catch (e) {
        console.log(e.message);
      }
    };

    getUserMetadata();
  }, [pagination.currentPage, search, filter]);

  const columns = [
    {
      name: "Apellido",
      type: "col-1",
      href: "/customer/",
      content: "lastName",
    },
    {
      name: "Nombre",
      type: "col-1",
      href: "/customer/",
      content: "firstName",
    },
    {
      name: "Sucursal",
      type: "col-1",
      href: "/customer/",
      content: "locationName",
    },
    {
      name: "DNI /  Cedula",
      type: "col-2",
      href: "/customer/",
      content: "dniCedula",
    },
  ];
  const filters = [
    {
      name: "Ningun filtro",
      value: "none",
      disabled: false,
    },
    {
      name: "Sucursal:",
      value: "",
      disabled: true,
    },
    ...locations.map((queue) => ({ name: queue.name, value: queue.id })),
  ];

  return (
    <>
      <Navbar />
      <main>
        <div className="page-header">
          <h1 className="page-title">Clientes</h1>
          <FontAwesomeIcon
            icon={faUsers}
            style={{ paddingRight: "3rem", paddingBottom: "0.7rem" }}
            size="3x"
          />
        </div>
        <hr />
        {clientList && (
          <>
            <div className="search-bar">
              <input
                className="search-bar"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="🔍 Buscar por cliente..."
              />
              {!search && (
                <select
                  className="filter-select"
                  style={{ opacity: filter === "none" ? 0.5 : 1 }}
                  value={filter}
                  onChange={(e) => filterFn(e.target.value)}
                >
                  {filters.map((filter) => (
                    <option disabled={filter.disabled} value={filter.value}>
                      {filter.name}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <ClientTable
              link={"/customer/"}
              list={!search ? clientList : searchResults}
              columns={columns}
            />
          </>
        )}
        <Pagination
          postsPerPage={pagination.postsPerPage}
          totalPosts={pagination.totalPosts}
          current={pagination.currentPage}
          paginate={paginate}
        />
      </main>
    </>
  );
}

export default Clients;
